<template>
    <div class="page-wrapper">
        <div class="main-title-wrap" hero-transition-group>
            <img
                src="@/assets/img/pastille.svg"
                class="pastille"
                alt="Houblon Mellön"
                data-inview="fadeInUp"
                data-delay="100"
            />
            <h1 class="title special" data-inview="fadeInUp" data-delay="200"><span v-html="contentIsLoaded ? content.introSimple1.surtitre : '&nbsp;'"></span></h1>
            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="200"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="300">
                <span v-html="contentIsLoaded ? content.introSimple1.titre : '&nbsp;'"></span>
            </h2>
        </div>

        <div class="story-wrap" v-if="contentIsLoaded" hero-transition-group>
            <div
                class="story"
                data-inview="fadeInUp"
                :data-delay="(1 * i) + '00'"
                v-for="(item, i) in content.historique" :key="'h-' + i"
            >
                <p class="title small">{{ item.date }}</p>
                <div class="img">
                    <img :src="item.image.url" :alt="item.image.titre" />
                </div>
                <h3 class="title small">{{ item.titre }}</h3>
                <p class="text">
                    <span v-html="item.texte"></span>
                </p>
            </div>
        </div>

        <div class="main-title-wrap" v-if="pageIsLoaded">
            <h1 class="title special" data-inview="fadeInUp" data-delay="100">{{ content.introSimple2.surtitre }}</h1>
            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="100"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="200">
                {{ content.introSimple2.titre }}
            </h2>
        </div>

        <div class="fondateurs" v-if="pageIsLoaded">
            <div class="fondateur" v-for="(item, i) in content.equipe" :key="'e-' + i">
                <div class="img" data-inview="revealRight" data-delay="100">
                    <img :src="item.image.url" :alt="item.image.titre" />
                </div>
                <div class="data" data-inview="fadeIn" data-delay="100">
                    <h3 class="title small">{{ item.titre }}</h3>
                    <p class="text vert">{{ item.soustitre }}</p>
                    <p class="text">
                        <span v-html="item.texte"></span>
                    </p>
                </div>
            </div>
        </div>

        <section class="hero team" v-if="pageIsLoaded">
            <div class="main-title-wrap">
                <h1 class="title special" data-inview="fadeInUp" data-delay="100">{{ content.introSimple3.surtitre }}</h1>
                <svg
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    data-inview="fadeInUp"
                    data-delay="100"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#BEA851"
                    />
                </svg>
                <h2 class="title" data-inview="fadeInUp" data-delay="200">
                    {{ content.introSimple3.titre }}
                </h2>
            </div>

            <div v-if="contentIsLoaded" class="img-wrap">
                <div class="row">
                    <img
                        :src="content.grilleEquipe.image1.url"
                        :alt="content.grilleEquipe.image1.titre"
                        data-inview="slideRight"
                        data-delay="100"
                    />
                </div>
                <div class="row">
                    <img
                        :src="content.grilleEquipe.image2.url"
                        :alt="content.grilleEquipe.image2.titre"
                        data-inview="fadeInDown"
                        data-delay="100"
                    />
                    <div class="bottom">
                        <img
                            :src="content.grilleEquipe.image4.url"
                            :alt="content.grilleEquipe.image4.titre"
                            data-inview="fadeInUp"
                            data-delay="100"
                        />
                        <img
                            :src="content.grilleEquipe.image5.url"
                            :alt="content.grilleEquipe.image5.titre"
                            data-inview="fadeInUp"
                            data-delay="200"
                        />
                    </div>
                </div>
                <div class="row last">
                    <img
                        :src="content.grilleEquipe.image3.url"
                        :alt="content.grilleEquipe.image3.titre"
                        data-inview="slideLeft"
                        data-delay="100"
                    />
                </div>

                <svg
                    class="corner top-left"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#85A785"
                    />
                </svg>
                <svg
                    class="corner top-right"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#BEA851"
                    />
                </svg>
                <svg
                    class="corner bottom-left"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#BEA851"
                    />
                </svg>
                <svg
                    class="corner bottom-right"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#85A785"
                    />
                </svg>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'About',

    components: {},

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 1)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
